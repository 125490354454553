import { Service, ServiceBinding, ServiceName, ServicesLoaders } from '@wix/thunderbolt-symbols'
import { ServicesManager } from '@wix/services-manager'

export const registerServices = async (
	servicesToLoad: Array<ServiceName>,
	servicesManager: ServicesManager,
	serviceLoaders: ServicesLoaders,
	siteFeaturesConfigs: Record<string, any>,
	pageFeatureConfigs?: Record<string, any>
): Promise<void> => {
	const services = await Promise.all(
		servicesToLoad.map((serviceName: ServiceName) =>
			serviceLoaders[serviceName]!()
				.then((service: Service) => ({
					definition: service.serviceDefinition,
					config: service.getConfig({
						siteConfig: siteFeaturesConfigs[serviceName],
						pageConfig: (pageFeatureConfigs ?? {})[serviceName],
					}),
					impl: service.impl,
				}))
				.catch((e) => {
					console.error(`Failed to load service ${serviceName}`, e)
					return { definition: '', config: {}, impl: null }
				})
		)
	)

	services.forEach((service: ServiceBinding<any, any>) => {
		if (service.definition && !servicesManager.hasService(service.definition)) {
			servicesManager.addService(service.definition, service.impl, service.config)
		}
	})
}
