import { BootstrapData } from '../../types'
import { BOOTSTRAP_DATA, MODELS_API, SERVICES_LOADER } from './moduleNames'
import { createServicesManager, createServicesMap, ServicesManager } from '@wix/services-manager'
import { FeatureName, IModelsAPI } from '@wix/thunderbolt-symbols'
import { serviceLoaders } from '../../serviceLoaders'
import { getServicesToLoad, registerServices } from 'feature-service-registrar'
import _ from 'lodash'

export interface IServices {
	init: () => Promise<void>
	getService: ServicesManager['getService']
}

const ServicesLoader = (bootstrapData: BootstrapData, modelsApi: IModelsAPI): IServices => {
	const { siteServicesConfigs, siteServicesNames } = bootstrapData
	const pageFeatureConfigs = modelsApi.getFeaturesPageConfigs()
	const servicesManager = createServicesManager(createServicesMap([]))

	return {
		init: async () => {
			const servicesToLoad = getServicesToLoad(_.union(_.keys(pageFeatureConfigs), _.keys(siteServicesNames)) as Array<FeatureName>)
			await registerServices(servicesToLoad, servicesManager, serviceLoaders, siteServicesConfigs, pageFeatureConfigs)
		},
		getService: (serviceDefinition) => {
			if (!servicesManager) {
				throw new Error('Service manager is not initialized')
			}
			return servicesManager.getService(serviceDefinition)
		},
	}
}

export default {
	factory: ServicesLoader,
	deps: [BOOTSTRAP_DATA, MODELS_API],
	name: SERVICES_LOADER,
}
